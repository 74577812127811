const baseTableMixin = {
  data: () => ({
    search: '',
  }),
  components: {
    BaseTable: () => import('@/components/base/BaseTable'),
    BaseTableHeader: () => import('@/components/base/BaseTableHeader'),
  },
  methods: {
    rowClick(item) {
      this.$emit('rowClick', item);
    },
    searchChange(search) {
      this.search = search;
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};

export default baseTableMixin;
